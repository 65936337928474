import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import proposalGateway from '../../../config/service';
import { getQueryParams, Notifications } from '../../../utils';

export const resetMsg = createAction('resetMsg');
export const resetStates = createAction('resetStates');

export const getContracts = createAsyncThunk('contracts/list', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contracts}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
export const getContractsById = createAsyncThunk('contracts/getById', async (id, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contracts}/${id}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
export const postContract = createAsyncThunk('contract/post', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.contracts}`, data);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
export const patchContract = createAsyncThunk('contract/patch', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.put(`${serviceEndpoints.contracts}/${data?.id}`, data);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
export const deleteContract = createAsyncThunk('delete-contract', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.contracts}/${data}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
export const getConvertedClients = createAsyncThunk('contract-converted-clients', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contracts}/client`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
export const getContractClientFilters = createAsyncThunk('contracts/list/client', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contracts}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getContractActivityLog = createAsyncThunk('contracts/log', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contractLog}?${getQueryParams(data)}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const fileExportContractActivityLog = createAsyncThunk('export-activity', async (data, { rejectWithValue }) => {
    const headers = {
        'x-timezone-offset': -new Date().getTimezoneOffset(),
        'x-response-format': 'excel',
    };
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contractLog}?${getQueryParams(data)}`, {
            headers,
        });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

export const getccList = createAsyncThunk('contracts/email', async (data, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.get(`${serviceEndpoints.contracts}/email`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const postCc = createAsyncThunk('contract/email/post', async ({ ccData, cb }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.post(`${serviceEndpoints.contracts}/email`, { ccData });
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        const { success, message } = response.data;
        if (success) {
            if (cb) cb();
            Notifications(message, 'success');
        }
        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});
export const deleteCc = createAsyncThunk('contract/email/delete', async ({ id, cb }, { rejectWithValue }) => {
    try {
        const response = await proposalGateway.delete(`${serviceEndpoints.contracts}/email/${id}`);
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(response.data);
        }
        const { success, message } = response.data;
        if (success) {
            if (cb) cb();
            Notifications(message, 'success');
        }

        return response.data;
    } catch (error) {
        if ((error.response && error.response.status === 401) || error.response.status === 400) {
            return rejectWithValue(error.response.data);
        }
    }
});

export const sendDocusignEmail = createAsyncThunk(
    'docusign/send-docusign',
    async ({ id, data, cb }, { rejectWithValue }) => {
        try {
            const response = await proposalGateway.post(`${serviceEndpoints.sendDocusign}/${id}`, data);
            const { success, message } = response.data;
            if (success) {
                if (cb) cb();
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const downloadDocument = createAsyncThunk(
    'docusign/download-document',
    async ({ id, docusignDownload }, { rejectWithValue }) => {
        try {
            let response;
            if (!docusignDownload) {
                response = await proposalGateway.get(`${serviceEndpoints.downloadContractDocument}/${id}`, {
                    responseType: 'blob',
                });
            } else {
                response = await proposalGateway.get(`${serviceEndpoints.downloadDocusign}/${id}`, {
                    responseType: 'blob',
                });
            }

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `Docusign_Document_${id}.pdf`;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            // if (cb) cb();

            return { success: true };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const downloadProposalDocument = createAsyncThunk(
    'download-proposal-document',
    async ({ id }, { rejectWithValue }) => {
        try {
            console.log('Fetching Signed URL...');

            const response = await proposalGateway.get(`${serviceEndpoints.downloadProposalDocument}/${id}`);
            const url = response.data;

            if (!url) throw new Error('Failed to fetch download URL');

            const fileResponse = await fetch(url);
            const blob = await fileResponse.blob();

            const fileUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = `Proposal_Document_${id}.pdf`;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(fileUrl);
            document.body.removeChild(a);

            return { success: true };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const slice = createSlice({
    name: 'contracts',
    initialState: {
        isLoading: false,
        sendDocusignEmailResponseLoading: false,
        sendDocusignEmailResponse: null,
        isLoadingGetCc: false,
        isLoadingAddCc: false,
        isLoadingCcDelete: false,
        contractDatas: {},
        successMessage: '',
        errorMessage: '',
        isDropdownLoading: false,
        getConvertedClientData: {},
        postResultContractData: {},
        emailList: {},
        getContractByIdData: {},
        getContractClientFilter: {},
        isLoadingLog: false,
        logList: {},
        isActivityDownloading: false,
        downloadProposalDocument: null,
        downloadProposalDocumentLoading: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getContracts.pending, (state) => {
                state.isLoading = true;
                state.contractDatas = {};
            })
            .addCase(getContracts.rejected, (state) => {
                state.isLoading = false;
                state.contractDatas = {};
            })
            .addCase(getContracts.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.contractDatas = payload;
            })

            .addCase(getContractActivityLog.pending, (state) => {
                state.isLoadingLog = true;
                state.logList = {};
            })
            .addCase(getContractActivityLog.rejected, (state) => {
                state.isLoadingLog = false;
                state.logList = {};
            })
            .addCase(getContractActivityLog.fulfilled, (state, { payload }) => {
                state.isLoadingLog = false;
                state.logList = payload;
            })

            .addCase(getccList.pending, (state) => {
                state.isLoadingGetCc = true;
                state.emailList = {};
            })
            .addCase(getccList.rejected, (state) => {
                state.isLoadingGetCc = false;
                state.emailList = {};
            })
            .addCase(getccList.fulfilled, (state, { payload }) => {
                state.isLoadingGetCc = false;
                state.emailList = payload;
            })

            .addCase(deleteCc.pending, (state) => {
                state.isLoadingCcDelete = true;
            })
            .addCase(deleteCc.rejected, (state) => {
                state.isLoadingCcDelete = false;
            })
            .addCase(deleteCc.fulfilled, (state) => {
                state.isLoadingCcDelete = false;
            })

            .addCase(postCc.pending, (state) => {
                state.isLoadingAddCc = true;
                state.emailList = {};
            })
            .addCase(postCc.rejected, (state) => {
                state.isLoadingAddCc = false;
                state.emailList = {};
            })
            .addCase(postCc.fulfilled, (state, { payload }) => {
                state.isLoadingAddCc = false;
                state.emailList = payload;
            })

            .addCase(postContract.pending, (state) => {
                state.isLoading = true;
                state.postResultContractData = {};
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(postContract.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.postResultContractData = {};
                state.errorMessage = payload.message;
            })
            .addCase(postContract.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.postResultContractData = payload;
                state.successMessage = payload.message;
            })

            .addCase(patchContract.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(patchContract.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
            })
            .addCase(patchContract.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })

            .addCase(deleteContract.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase(deleteContract.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.successMessage = payload.message;
            })
            .addCase(deleteContract.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.errorMessage = payload.message;
            })

            .addCase(getContractsById.pending, (state) => {
                state.isLoading = true;
                state.getContractByIdData = {};
            })
            .addCase(getContractsById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.getContractByIdData = payload;
            })
            .addCase(getContractsById.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.getContractByIdData = {};
            })

            .addCase(getConvertedClients.pending, (state) => {
                state.isDropdownLoading = true;
                state.getConvertedClientData = {};
            })
            .addCase(getConvertedClients.rejected, (state) => {
                state.isDropdownLoading = false;
                state.getConvertedClientData = {};
            })
            .addCase(getConvertedClients.fulfilled, (state, { payload }) => {
                state.isDropdownLoading = false;
                state.getConvertedClientData = payload;
            })

            .addCase(getContractClientFilters.pending, (state) => {
                state.getContractClientFilter = {};
            })
            .addCase(getContractClientFilters.rejected, (state) => {
                state.getContractClientFilter = {};
            })
            .addCase(getContractClientFilters.fulfilled, (state, { payload }) => {
                state.getContractClientFilter = payload;
            })
            .addCase(sendDocusignEmail.pending, (state) => {
                state.sendDocusignEmailResponseLoading = true;
                state.sendDocusignEmailResponse = null;
            })
            .addCase(sendDocusignEmail.rejected, (state, { payload }) => {
                state.sendDocusignEmailResponseLoading = false;
                state.sendDocusignEmailResponse = payload;
            })
            .addCase(sendDocusignEmail.fulfilled, (state, { payload }) => {
                state.sendDocusignEmailResponseLoading = false;
                state.sendDocusignEmailResponse = payload;
                state.successMessage = payload.message;
            })

            .addCase(fileExportContractActivityLog.pending, (state) => {
                state.isActivityDownloading = true;
            })
            .addCase(fileExportContractActivityLog.fulfilled, (state, { payload }) => {
                if (payload?.data?.url) {
                    const link = document.createElement('a');
                    link.href = payload?.data?.url;
                    link.download = payload?.data?.filename;
                    link.click();
                }
                state.isActivityDownloading = false;

                state.exportActivityData = payload;
            })
            .addCase(fileExportContractActivityLog.rejected, (state) => {
                state.isActivityDownloading = false;
            })

            .addCase(downloadDocument.pending, (state, action) => {
                const { id } = action.meta.arg;
                state.downloadDocumentResponseLoading = id;
                state.downloadDocumentResponse = null;
            })
            .addCase(downloadDocument.rejected, (state, { payload }) => {
                state.downloadDocumentResponseLoading = false;
                state.downloadDocumentResponse = { success: false };
            })
            .addCase(downloadDocument.fulfilled, (state, { payload }) => {
                state.downloadDocumentResponseLoading = false;
                state.downloadDocumentResponse = payload;
            })

            .addCase(downloadProposalDocument.pending, (state, action) => {
                const { id } = action.meta.arg;
                state.downloadProposalDocumentLoading = id;
                state.downloadProposalDocument = null;
            })
            .addCase(downloadProposalDocument.rejected, (state, { payload }) => {
                state.downloadProposalDocumentLoading = false;
                state.downloadProposalDocument = { success: false };
            })
            .addCase(downloadProposalDocument.fulfilled, (state, { payload }) => {
                state.downloadProposalDocumentLoading = false;
                state.downloadProposalDocument = payload;
            })

            .addCase('resetMsg', (state) => {
                state.errorMessage = '';
                state.successMessage = '';
            })
            .addCase('resetStates', (state) => {
                state.getContractByIdData = {};
            });
    },
});

export default slice.reducer;
