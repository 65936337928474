export default {
    modules: {
        DASHBOARD: 'DASHBOARD',
        EMPLOYEES_MANAGEMENT: 'EMPLOYEES',
        PERMISSION_MANAGEMENT: 'PERMISSION',
        PROPOSAL_MANAGEMENT: 'PROPOSALS',
        PROPOSAL_BREAKDOWN: 'PROPOSAL_BREAKDOWN',
        CONVERSIONS: 'CONVERSIONS',
        CONTRACT: 'CONTRACT',
        DOCUMENTS: 'DOCUMENTS',
        PORTFOLIO: 'PORTFOLIO',
        DOMAINS: 'DOMAINS',
        TECHNOLOGIES: 'TECHNOLOGIES',
        TECHNOLOGY: 'TECHNOLOGY',
        CATEGORY: 'CATEGORY',
        PROJECTS: 'PROJECTS',
        PORTFOLIOS: 'PORTFOLIOS',
        TEMPLATES: 'TEMPLATES',
        MESSAGES: 'MESSAGES',
        SMTP_SETTINGS: 'SMTP_SETTINGS',
        WHITELIST_IP: 'WHITELIST_IP',
        PORTFOLIO_SETTINGS: 'PORTFOLIO_SETTINGS',
        SUGGESTIONS: 'SUGGESTIONS',
        CLIENT: 'CLIENT',
        PROSPECT_TEMPLATE: 'TEMPLATE',
        NEWLEAD: 'NEWLEAD',
        UPWORK_SETTINGS: 'UPWORK_SETTINGS',
    },
    EMPLOYEE_COUNT: 'USER_COUNT',
    PROPOSAL_COUNT: 'PROPOSAL_COUNT',
    CONVERSION_COUNT: 'CONVERSION_COUNT',
    DOCUMENT_COUNT: 'DOCUMENT_COUNT',
    PROPOSAL_GRAPH: 'PROPOSAL_GRAPH',
    CONVERSION_GRAPH: 'CONVERSION_GRAPH',
    ADD_EMPLOYEES: 'ADD_USER',
    EXPORT_EMPLOYEES: 'EXPORT_USER',
    LIST_EMPLOYEES: 'LIST_USERS',
    VIEW_EMPLOYEES: 'VIEW_USER',
    EDIT_EMPLOYEES: 'EDIT_USER',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    DEACTIVATE_EMPLOYEES: 'DEACTIVATE_USER',
    DELETE_EMPLOYEES: 'DELETE_USER',
    ACTIVATE_EMPLOYEES: 'ACTIVATE_USER',
    ADD_DESIGNATION: 'ADD_DESIGNATION',
    LIST_DESIGNATION: 'LIST_DESIGNATIONS',
    EDIT_DESIGNATION: 'EDIT_DESIGNATION',
    DELETE_DESIGNATION: 'DELETE_DESIGNATION',
    ADD_PROPOSAL: 'ADD_PROPOSAL',
    LIST_PROPOSAL: 'LIST_PROPOSALS',
    LIST_ALL_PROPOSALS: 'LIST_ALL_PROPOSALS',
    VIEW_PROPOSAL: 'VIEW_PROPOSAL',
    EDIT_PROPOSAL: 'EDIT_PROPOSAL',
    INTEGRATE_PROPOSAL: 'INTEGRATE_PROPOSAL',
    ACTIVATE_PROPOSAL: 'ACTIVATE_PROPOSAL',
    DEACTIVATE_PROPOSAL: 'DEACTIVATE_PROPOSAL',
    UPDATE_STATUS_PROPOSAL_FEATURE: 'UPDATE_STATUS_PROPOSAL_FEATURE',
    DOWNLOAD_PROPOSAL: 'DOWNLOAD_PROPOSAL',
    EDIT_ESTIMATION_PROPOSAL_FEATURE: 'EDIT_ESTIMATION_PROPOSAL_FEATURE',
    SHARE_PROPOSAL: 'SHARE_PROPOSAL',
    EXPORT_PROPOSAL: 'EXPORT_PROPOSAL',
    COPY_LINK_PROPOSAL: 'COPY_LINK_PROPOSAL',
    EXPORT_ACTIVITY_LOG: 'EXPORT_ACTIVITY_LOG',
    UPDATE_STATUS_PROPOSAL: 'UPDATE_STATUS_PROPOSAL',
    ACTIVITY_LOG: 'ACTIVITY_LOG',
    MOVE_TO_CONVERSION: 'MOVE_TO_CONVERSION',
    ADD_FEATURE: 'ADD_FEATURE',
    PROPOSAL_FEATURE_BREAKDOWN_VIEW: 'PROPOSAL_FEATURE_BREAKDOWN_VIEW',
    LIST_CONVERSIONS: 'LIST_CONVERSIONS',
    VIEW_CONVERSION: 'VIEW_CONVERSION',
    CHANGE_STATUS_CONVERSION: 'CHANGE_STATUS_CONVERSION',
    EXPORT_CONVERSION: 'EXPORT_CONVERSION',
    PROJECT_INFO: 'PROJECT_INFO',
    GENERATE_SERVICE_AGREEMENT: 'GENERATE_SERVICE_AGREEMENT',
    DOWNLOAD_CONVERSION_PROPOSAL: 'DOWNLOAD_CONVERSION_PROPOSAL',
    ADD_DOCUMENTS: 'ADD_DOCUMENT',
    LIST_DOCUMENTS: 'LIST_DOCUMENTS',
    DOWNLOAD_DOCUMENTS: 'DOWNLOAD_DOCUMENT',
    DELETE_DOCUMENTS: 'DELETE_DOCUMENT',
    ADD_DOMAIN: 'ADD_DOMAIN',
    EDIT_DOMAIN: 'EDIT_DOMAIN',
    DELETE_DOMAIN: 'DELETE_DOMAIN',
    LIST_DOMAINS: 'LIST_DOMAINS',
    ADD_PROJECT: 'ADD_PROJECT',
    EDIT_PROJECT: 'EDIT_PROJECT',
    VIEW_PROJECT: 'VIEW_PROJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    LIST_PROJECTS: 'LIST_PROJECTS',
    ADD_MESSAGE: 'ADD_MESSAGE',
    DELETE_MESSAGE: 'DELETE_MESSAGE',
    LIST_MESSAGE: 'LIST_MESSAGE',
    VIEW_MESSAGE: 'VIEW_MESSAGE',
    VIEW_PORTFOLIOS: 'VIEW_PORTFOLIOS',
    LIST_PORTFOLIOS: 'LIST_PORTFOLIOS',
    ADD_PORTFOLIOS: 'ADD_PORTFOLIOS',
    EDIT_PORTFOLIOS: 'EDIT_PORTFOLIOS',
    UPWORK_LINK_VIEW: 'UPWORK_LINK_VIEW',
    DELETE_PORTFOLIOS: 'DELETE_PORTFOLIOS',
    COPY_LINK_PORTFOLIOS: 'COPY_LINK_PORTFOLIOS',
    SEND_MAIL_PORTFOLIOS: 'SEND_MAIL_PORTFOLIOS',
    DOWNLOAD_PORTFOLIOS: 'DOWNLOAD_PORTFOLIOS',
    LIST_VIEW_LOG_PORTFOLIOS: 'LIST_VIEW_LOG_PORTFOLIOS',
    UPDATE_PER_PROJECT_IMAGE_COUNT_PORTFOLIOS: 'UPDATE_PER_PROJECT_IMAGE_COUNT_PORTFOLIOS',
    EDIT_TEMPLATE: 'EDIT_TEMPLATE',
    LIST_TEMPLATES: 'LIST_TEMPLATES',
    DELETE_TEMPLATE: 'DELETE_TEMPLATE',
    ADD_TEMPLATE: 'ADD_TEMPLATE',
    VIEW_TEMPLATE: 'VIEW_TEMPLATE',
    DEFAULT_TEMPLATE: 'SET_DEFAULT_TEMPLATE',
    EDIT_TECHNOLOGY: 'EDIT_TECHNOLOGY',
    LIST_CATEGORIES: 'LIST_CATEGORIES',
    ADD_TECHNOLOGY: 'ADD_TECHNOLOGY',
    EDIT_CATEGORY: 'EDIT_CATEGORY',
    ADD_CATEGORY: 'ADD_CATEGORY',
    DELETE_TECHNOLOGY: 'DELETE_TECHNOLOGY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    LIST_TECHNOLOGIES: 'LIST_TECHNOLOGIES',
    ADD_SMTP: 'ADD_SMTP',
    VIEW_SMTP: 'VIEW_SMTP',
    EDIT_UPWORK_SETTINGS: 'EDIT_UPWORK_SETTINGS',
    ADD_WHITELIST_IP: 'ADD_WHITELIST_IP',
    EDIT_WHITELIST_IP: 'EDIT_WHITELIST_IP',
    DELETE_WHITELIST_IP: 'DELETE_WHITELIST_IP',
    LIST_WHITELIST_IP: 'LIST_WHITELIST_IP',
    DELETE_CLIENT: 'DELETE_CLIENT',
    LIST_CLIENT: 'LIST_CLIENT',
    ADD_CLIENT: 'ADD_CLIENT',
    EDIT_CLIENT: 'EDIT_CLIENT',
    VIEW_CLIENT: 'VIEW_CLIENT',
    EXPORT_CLIENT: 'EXPORT_CLIENT',
    ADD_SUGGESTION: 'ADD_SUGGESTION',
    VIEW_SUGGESTION: 'VIEW_SUGGESTION',
    LIST_SUGGESTIONS: 'LIST_SUGGESTIONS',
    EDIT_SUGGESTION: 'EDIT_SUGGESTION',
    DELETE_SUGGESTION: 'DELETE_SUGGESTION',
    ADD_PROPOSAL_MODULE: 'ADD_PROPOSAL_MODULE',
    VIEW_PROPOSAL_MODULE: 'VIEW_PROPOSAL_MODULE',
    EDIT_PROPOSAL_MODULE: 'EDIT_PROPOSAL_MODULE',
    DELETE_PROPOSAL_MODULE: 'DELETE_PROPOSAL_MODULE',
    ADD_PROPOSAL_FEATURE_ABOVE: 'ADD_PROPOSAL_FEATURE_ABOVE',
    ADD_PROPOSAL_FEATURE_BELOW: 'ADD_PROPOSAL_FEATURE_BELOW',
    ADD_PROPOSAL_SUB_FEATURE_ABOVE: 'ADD_PROPOSAL_SUB_FEATURE_ABOVE',
    ADD_PROPOSAL_SUB_FEATURE_BELOW: 'ADD_PROPOSAL_SUB_FEATURE_BELOW',
    ADD_PROPOSAL_SUB_FEATURE: 'ADD_PROPOSAL_SUB_FEATURE',
    EDIT_PROPOSAL_FEATURE: 'EDIT_PROPOSAL_FEATURE',
    EDIT_PROPOSAL_SUB_FEATURE: 'EDIT_PROPOSAL_SUB_FEATURE',
    DELETE_PROPOSAL_FEATURE: 'DELETE_PROPOSAL_FEATURE',
    DELETE_PROPOSAL_SUB_FEATURE: 'DELETE_PROPOSAL_SUB_FEATURE',
    MOVE_PROPOSAL_MODULE: 'MOVE_PROPOSAL_MODULE',
    MOVE_PROPOSAL_FEATURE: 'MOVE_PROPOSAL_FEATURE',
    MOVE_PROPOSAL_SUB_FEATURE: 'MOVE_PROPOSAL_SUB_FEATURE',
    DOWNLOAD_IMAGE_PROJECT: 'DOWNLOAD_IMAGE_PROJECT',
    DOWNLOAD_PROJECT: 'DOWNLOAD_PROJECT',
    REORDER_PROJECTS_PORTFOLIOS: 'REORDER_PROJECTS_PORTFOLIOS',
    VIEW_ESTIMATION_TOTALS: 'VIEW_ESTIMATION_TOTALS',
    ATTACH_PDF: 'ATTACH_PDF',
    ATTACH_EXCEL: 'ATTACH_EXCEL',
    DUPLICATE_PROPOSAL: 'DUPLICATE_PROPOSAL',
    ADD_PORTFOLIO_TEMPLATE: 'ADD_PORTFOLIO_TEMPLATE',
    EDIT_PORTFOLIO_TEMPLATE: 'EDIT_PORTFOLIO_TEMPLATE',
    VIEW_PORTFOLIO_TEMPLATE: 'VIEW_PORTFOLIO_TEMPLATE',
    LIST_PORTFOLIO_TEMPLATES: 'LIST_PORTFOLIO_TEMPLATES',
    DELETE_PORTFOLIO_TEMPLATE: 'DELETE_PORTFOLIO_TEMPLATE',
    SET_DEFAULT_PORTFOLIO_TEMPLATE: 'SET_DEFAULT_PORTFOLIO_TEMPLATE',
    ADD_NEWLEAD: 'ADD_NEWLEAD',
    LIST_NEWLEAD: 'LIST_NEWLEAD',
    VIEW_NEWLEAD: 'VIEW_NEWLEAD',
    EDIT_NEWLEAD: 'EDIT_NEWLEAD',
    DELETE_NEWLEAD: 'DELETE_NEWLEAD',
    EXPORT_NEWLEAD: 'EXPORT_NEWLEAD',
    USER_LOGIN_HISTORY: 'USER_LOGIN_HISTORY',
    LIST_CONTRACT: 'LIST_CONTRACT',
    EDIT_CONTRACT: 'EDIT_CONTRACT',
    DELETE_CONTRACT: 'DELETE_CONTRACT',
    DOWNLOAD_CONTRACT: 'DOWNLOAD_CONTRACT',
    GENERATE_AGREEMENT: 'GENERATE_AGREEMENT',
    ADD_CONTRACT: 'ADD_CONTRACT',
};
